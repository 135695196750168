<template>
  <div :class="[$style.awPage]">
    <client-only>
      <component
        :is="pageComponent"
        v-if="pageComponent"
        :key="urlResource.resourceType === 'search' && urlResource.slug"
      />
      <div v-if="!pageComponent">
        <aw-spinner color="currentColor" :class="$style.spinner" />
      </div>
    </client-only>
  </div>
</template>

<script>
  import {
    defineAsyncComponent,
    onMounted,
    onUnmounted,
    nextTick,
    markRaw,
    computed,
    watch,
    effectScope,
  } from 'vue';
  import { mapActions, mapState } from 'pinia';
  import { useRoute, createError, useNuxtApp, useAsyncData } from 'nuxt/app';
  import { isCategoryProductList } from '~~/common/utils';
  import { loadBreadcrumb } from '~~/common/utils/urlResource';
  import languageDataMixin from '~~/common/mixins/languageDataMixin';
  import productListHistoryMixin from '~~/common/mixins/productListHistoryMixin';
  import searchPage from '~~/common/components/Page/ProductList/AwProductSearch';
  import { URL_PREFIX } from '~/awPlugins/app-config';
  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useContentsStore } from '~~/common/stores/contents';
  import { useSearchStore } from '~~/common/stores/search';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useCategoryStore } from '~~/common/stores/category';
  import { useProductsStore } from '~~/common/stores/products';
  import { useProductStore } from '~~/common/stores/product';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useBreadcrumbStore } from '~~/common/stores/breadcrumb';
  import AwCatalogDetailsPage from '~~/shop/components/Page/Catalog/AwCatalogDetailsPage.vue';
  import { usePromotionStore } from '~~/shop/stores/promotion.js';
  import { useSeoStore } from '~~/common/stores/seo.js';
  import pageInstanceMixin from '~~/common/mixins/pageInstanceMixin';
  import { PUSH_PRODUCT_VIEW } from '~~/common/plugins/aw-analytics.js';

  const productPage = defineAsyncComponent(() => import('~/components/Page/Product/Page'));
  const productListPage = defineAsyncComponent(() => import('~/components/Page/ProductList/Page'));
  const categoryListPage = defineAsyncComponent(() => import('~~/common/components/Page/Category/Page'));
  const contentPage = defineAsyncComponent(() => import('~/components/Page/Content/Page'));
  const AwSpinner = defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner'));

  const pageStatuses = {
    INITIALIZE: 'INITIALIZE',
    SERVER_SIDE_LOADED: 'SERVER_SIDE_LOADED',
    CLIENT_SIDE_LOADING: 'CLIENT_SIDE_LOADING',
    CLIENT_SIDE_LOADED: 'CLIENT_SIDE_LOADED',
  };

  export default {
    name: 'AwPage',
    components: {
      productPage,
      productListPage,
      categoryListPage,
      contentPage,
      AwSpinner,
    },
    mixins: [
      languageDataMixin,
      productListHistoryMixin,
      pageInstanceMixin,
    ],
    async setup () {
      const { $awSFMCClient, $logger } = useNuxtApp();
      const route = useRoute();
      const productStore = useProductStore();
      const productId = computed(() => productStore.data?.id);
      const promotionStore = usePromotionStore();
      const urlResourceStore = useUrlResourceStore();
      const searchStore = useSearchStore();
      const urlResource = computed(() => {
        return urlResourceStore.data;
      });
      const asyncKey = computed(() => {
        return `${urlResource.value.resourceType}_${urlResource.value.resourceId}`;
      });
      let searchTimeoutId = null;
      let productViewTimeoutId = null;
      onMounted(() => {
        watch(
          urlResource,
          (newVal) => {
            if (newVal?.resourceType === 'product' && newVal.resourceId) {
              clearTimeout(productViewTimeoutId);
              productViewTimeoutId = setTimeout(() => {
                $awSFMCClient[PUSH_PRODUCT_VIEW]({
                  sku: newVal.resourceId,
                });
              }, 1000);
            }
          },
          {
            immediate: true,
          },
        );
        watch(
          () => `${productId.value}_${urlResource.value?.resourceType}`,
          () => {
            if (urlResource.value?.resourceType === 'product' && productId.value) {
              clearTimeout(searchTimeoutId);
              searchTimeoutId = setTimeout(() => {
                searchStore.searchesLog({
                  type: 'view',
                  resultId: productId.value,
                });
              }, 1000);
            }
          },
          {
            immediate: true,
          },
        );
      });
      onUnmounted(() => {
        clearTimeout(productViewTimeoutId);
        clearTimeout(searchTimeoutId);
      });
      const scope = effectScope();
      const { data: nope0, error: productError } = await useAsyncData(
        asyncKey.value,
        async () => {
          const err404 = createError({
            statusCode: 404,
            fatal: true,
          });
          try {
            switch (urlResource.value?.resourceType) {
            case 'product':
              {
                if (!urlResource.value?.resourceId) {
                  throw err404;
                }
                const product = await productStore.fetchMainProductForProductPage({
                  loc: route.query?.loc,
                  identifierType: 'sku',
                  id: urlResource.value.resourceId,
                });
                if (!product?.id) {
                  throw err404;
                }
              }
              break;
            case 'catalog':
              {
                const catalogRequestError = await promotionStore.fetchCatalog(urlResource.value?.resourceId);
                if (catalogRequestError) {
                  throw err404;
                }
              }
              break;
            }
          } catch (err) {
            if (err === err404) {
              throw err;
            }
            $logger.error(err);
          }
          return {};
        },
        {
          default: () => ({}),
          watch: [asyncKey],
        },
      );
      scope.run(() => {
        if (productError.value?.statusCode === 404) {
          throw productError.value;
        }
      });
      return {
        urlResource,
        nope0,
      };
    },
    data () {
      return {
        pageComponent: false,
        pageStatus: pageStatuses.INITIALIZE,
      };
    },
    computed: {
      ...mapState(useProductStore, {
        productCategories: state => state.data.categories,
        productName: state => state.data?.selectedVariant?.name,
        productId: state => state.data?.id,
      }),
      ...mapState(useCategoryStore, {
        categories: state => state.categories,
      }),
      ...mapState(useProductsStore, {
        itemsPerPage: state => state.itemsPerPage,
        sortKey: state => state.sortKey,
        sortBy: state => state.sortBy,
        filterBy: state => state.filterBy,
        productListHistoryInformation: state => state.productListHistoryInformation,
      }),
      ...mapState(useDeliveryStore, {
        deliverySetup: state => state.setup,
      }),
      isWebshop () {
        return this.$router.currentRoute.value.path.startsWith('/shop');
      },
    },
    watch: {
      async deliverySetup () {
        const categoryStore = useCategoryStore();
        if (this.urlResource.resourceType === 'boutique' || (this.urlResource.resourceType === 'category' && isCategoryProductList(categoryStore.getCategoryFromTree))) {
          let id = this.urlResource?.resourceId;
          if (id.includes('|')) {
            id = id.split('|')[1];
          }
          await categoryStore.fetchCategoryById(id);
        }
      },
    },
    async created () {
      await this.initServerSide();
      this.pageStatus = pageStatuses.SERVER_SIDE_LOADED;
      if (import.meta.client && this.pageStatus !== pageStatuses.CLIENT_SIDE_LOADING) {
        this.pageStatus = pageStatuses.CLIENT_SIDE_LOADING;
        await this.initClientSide();
        this.pageStatus = pageStatuses.CLIENT_SIDE_LOADED;
      }
    },
    async mounted () {
      if (this.pageStatus === pageStatuses.SERVER_SIDE_LOADED) {
        this.pageStatus = pageStatuses.CLIENT_SIDE_LOADING;
        await this.initClientSide();
        this.pageStatus = pageStatuses.CLIENT_SIDE_LOADED;
      }
    },
    methods: {
      setPageComponent (newVal) {
        if (typeof newVal === 'object') {
          this.pageComponent = markRaw(newVal);
        } else {
          this.pageComponent = newVal;
        }
      },
      async initServerSide () {
        const id = this.urlResource?.resourceId;
        const categoryStore = useCategoryStore();
        const productsStore = useProductsStore();
        const contentsStore = useContentsStore();
        const promotionStore = usePromotionStore();
        const seoStore = useSeoStore();
        const route = useRoute();

        switch (this.urlResource?.resourceType) {
        case 'category':
          categoryStore.setTreeId(Number(id));
          if (!categoryStore.getCategoryFromTree) {
            throw createError({
              statusCode: 404,
              fatal: true,
            });
          } else if (isCategoryProductList(categoryStore.getCategoryFromTree)) {
            productsStore.setFilterFromQuery(route.query);
            if (!(this.isReturningFromProductPage && this.isReturningEnabled)) {
              await Promise.all([
                productsStore.fetchProducts({
                  isCached: true,
                  categoryId: id,
                  itemsPerPage: this.itemsPerPage || 16,
                  page: 1,
                  filterParams: this.filterBy,
                  sortKey: this.sortKey || null,
                  sortBy: this.sortBy || null,
                }),
                contentsStore.fetchStaticContentByResourceId({
                  type: 'product_list_content',
                  resourceId: id,
                }),
              ]);
            }
          }
          break;
        case 'search':
          productsStore.setFilterFromQuery(route.query);
          productsStore.setSearchText(Array.from(new URLSearchParams(id).values()));
          break;
        case 'boutique':
          {
            productsStore.setFilterFromQuery(route.query);
            const isReturningFromProductPage = this.productListHistoryInformation?.location?.includes(route.fullPath);
            const isReturningEnabled = this.productListHistoryInformation?.returnEnabled;

            await Promise.all([
              categoryStore.fetchCategoryById(id.split('|')[1]),
              contentsStore.fetchStaticContentByResourceId({
                type: 'product_list_content',
                resourceId: id.split('|')[1],
              }),
            ]);
            if (!(isReturningFromProductPage && isReturningEnabled)) {
              await productsStore.fetchProducts({
                isCached: true,
                boutiqueId: id.split('|')[1],
                itemsPerPage: this.itemsPerPage || 16,
                page: 1,
                filterParams: this.filterBy,
                sortKey: this.sortKey || null,
                sortBy: this.sortBy || null,
              });
            }
          }
          break;
        case 'catalog':
          if (promotionStore.catalog?.title) {
            seoStore.setPageTitlePrefix({
              pageTitle: promotionStore.catalog.title,
            });
          }
          break;
        }
      },
      async initClientSide () {
        const id = this.urlResource?.resourceId;
        const categoryStore = useCategoryStore();
        const contentsStore = useContentsStore();
        const productsStore = useProductsStore();
        const productStore = useProductStore();
        const promotionStore = usePromotionStore();
        const seoStore = useSeoStore();
        const route = useRoute();

        switch (this.urlResource?.resourceType) {
        case 'product':
          if (productStore.data?.id) {
            this.setPageComponent(productPage);
          }
          break;
        case 'search':
          this.setPageComponent(searchPage);
          break;
        case 'category':
          if (!categoryStore.getCategoryFromTree) {
            throw createError({
              statusCode: 404,
              fatal: true,
            });
          } else if (isCategoryProductList(categoryStore.getCategoryFromTree)) {
            await categoryStore.fetchCategoryById(id);
            if (this.isReturningFromProductPage && this.isReturningEnabled) {
              productsStore.restoreFromProductListHistoryInformation();
            }
            this.setPageComponent(productListPage);

            if (this.isReturningFromProductPage) {
              const productId = this.productListHistoryInformation.productId;
              this.scrollToProductId(productId);
            }
          } else {
            this.setPageComponent(categoryListPage);
          }
          break;
        case 'boutique':
          {
            const isReturningFromProductPage = this.productListHistoryInformation?.location?.includes(route.fullPath);
            const isReturningEnabled = this.productListHistoryInformation?.returnEnabled;

            if (isReturningFromProductPage && isReturningEnabled) {
              productsStore.restoreFromProductListHistoryInformation();
            }

            this.setPageComponent(productListPage);

            if (isReturningFromProductPage) {
              const productId = this.productListHistoryInformation.productId;
              nextTick(() => {
                if (isReturningEnabled) {
                  const productCard = document.querySelector('.prodPageReturn-' + productId);
                  if (productCard) {
                    requestAnimationFrame(() => {
                      productCard.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                      });
                    });
                  }
                } else {
                  window.scrollTo(0, 0);
                }
              });
            }
          }
          break;
        case 'cms_content':
          await contentsStore.fetchContent({ pageId: id });
          useUserInterfaceStore().setBackground({ ...contentsStore.data });
          this.setPageComponent(contentPage);
          await seoStore.fetchSeoData({
            seoModule: 'cms_content',
            id: this.urlResource.seoResourceId,
          });
          if (contentsStore.data.inCareerMenu || contentsStore.data.contentRootTypes.includes('career')) {
            seoStore.setCareerPageTitle({
              ...seoStore.data,
              pageTitle: seoStore.data.title,
              title: null,
            });
          }
          break;
        case 'catalog':
          this.setPageComponent(AwCatalogDetailsPage);
          this.setBreadcrumbs([
            {
              token: this.$awt('aw.common.category.home'),
              to: '/',
              disabled: false,
            },
            {
              token: this.$awt('aw.cms.catalog.list.breadcrumb'),
              to: '/catalog',
              disabled: false,
            },
            {
              token: promotionStore.catalog.title,
              to: '',
              disabled: true,
            },
          ]);
          break;
        }

        const breadCrumbs = [
          {
            to: this.localePath('/'),
            disabled: false,
            token: this.$awt('aw.common.category.home'),
          },
          ...(this.isShop && this.isWebshop ? [{
            to: this.localePath('/shop'),
            disabled: false,
            token: this.$awt('aw.common.category.shop'),
          }] : []),
        ];
        const calculated = loadBreadcrumb({
          urlResource: this.urlResource,
          path: route.path,
          category: categoryStore.getCategoryFromTree,
          productCategories: (this.productCategories || []).concat({
            name: this.productName,
            slug: '',
          }),
          cmsToken: contentsStore?.data?.title || '',
          urlPrefix: URL_PREFIX,
          contentRootTypes: contentsStore?.data?.contentRootTypes,
          $awt: this.$awt,
        })?.();
        if (calculated) {
          const breadcrumbs = breadCrumbs.concat(calculated.map(b => ({
            ...b,
            to: `${this.localeCodeToUrl}${b.to}`,
          })),
          );
          this.setBreadcrumbs(breadcrumbs);
        }
      },
      ...mapActions(useBreadcrumbStore, ['setBreadcrumbs']),
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.awPage {
  @extend %page-min-height;
  margin-top: 0;
}

.spinner {
  color: $color-text-primary;
}
</style>
