<template>
  <div :class="[$style.wrap, {[$style.outlined]: outlined}, {[$style.arrowRight]: arrowDirection === 'right'}, {[$style.arrowLeft]: arrowDirection === 'left'}]">
    <component
      :is="buttonTag"
      :class="[$style.button]"
      type="button"
      :title="label"
      v-bind="$attrs"
    >
      <span :class="$style.buttonText">
        <slot>
          {{ label }}
        </slot>
      </span>
    </component>
    <div :class="[$style.buttonSecondary, {[$style.buttonSecondaryPointerEventNone]: !allowPevent}]">
      <slot name="secondary" />
    </div>
  </div>
</template>

<script>

  const validArrowDirections = ['right', 'left'];

  export default {
    name: 'AwArrowButton',
    inheritAttrs: false,
    props: {
      outlined: {
        type: Boolean,
        default: false,
      },
      allowPevent: {
        type: Boolean,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      arrowDirection: {
        type: String,
        required: true,
        validator: arrowDirection => validArrowDirections.includes(arrowDirection),
      },
      buttonTag: {
        type: String,
        default: 'button',
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrap {
  position: relative;
  color: $color-white;
}

.arrowRight .button::after,
.arrowLeft .button::after {
  position: absolute;
  width: 34px;
  height: 34px;
  content: "";
  border-radius: 8px;
  background: inherit;
}

.arrowRight .outlined .button::after {
  right: -15px;
}

.arrowRight .button {
  justify-content: left;
  text-align: left;
}

.arrowRight .button::after {
  right: -15px;
  transform: rotate(45deg);
  border-top: 2px solid $color-brand-secondary;
  border-right: 2px solid $color-brand-secondary;
  border-bottom: transparent;
  border-left: transparent;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrowLeft .outlined .button::after {
  right: -15px;
}

.arrowLeft .button {
  justify-content: right;
  margin-left: 15px;
  text-align: right;
}

.arrowLeft .button::after {
  left: -15px;
  transform: rotate(-45deg);
  border-top: 2px solid $color-brand-secondary;
  border-right: transparent;
  border-bottom: transparent;
  border-left: 2px solid $color-brand-secondary;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.outlined {
  color: $color-brand-secondary;

  .button {
    background: $color-seashell;
  }
}

.button {
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  height: 48px;
  margin-right: 19px;
  padding-right: 18px;
  padding-left: 16px;
  cursor: pointer;
  border: 2px solid $color-brand-secondary;
  border-radius: 8px;
  background: $color-brand-secondary;

  @include lg() {
    &:hover {
      font-weight: $font-weight-bold;
    }
  }

  &Text {
    z-index: 1;
    display: -webkit-inline-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &Secondary {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 100%;
    padding: 0;
    border: 0;
    background: transparent;

    &PointerEventNone {
      pointer-events: none;
    }
  }
}
</style>
