<template>
  <aw-product-list
    :is-whole-loading-completed="isWholeLoadingCompleted"
    :is-more-loading-completed="isMoreLoadingCompleted"
    :item-count="itemCount"
    :no-items-token="'aw.search_page.no_items_token'"
    is-search
    @load-more-product-items="loadMoreProductItems"
    @order-product-items="orderProductItems"
    @filter-product-items="throttledSearch"
  >
    <template #content>
      <aw-product-list-header
        v-if="itemCount"
      >
        <template #listName>
          <lv-heading
            :ref="Navigation[ID_SEARCH_LIST_TOP]"
            tag="h1"
            level="2"
            :class="$style.title"
            version2
          >
            <span v-if="isMultipleSubstring">
              {{ $awt('aw.search_page.multiple_substring_header.title', { count: searchText.length }) }}
            </span>
            <span v-else>
              {{ $awt('aw.search_page.single_substring_header.title', { searchText: searchText[0] || '' }) }}
            </span>
          </lv-heading>
        </template>
        <template #filters>
          <aw-product-list-order-by-and-mobile-filter-modal
            v-if="screenRange['tablet-min']"
            :is-filterable="false"
          />
        </template>
      </aw-product-list-header>
      <!-- region Multiple substrings -->
      <template v-if="isMultipleSubstring && isWholeLoadingCompleted">
        <lv-grid type="row" wrap="wrap">
          <template v-for="(text, i) in searchText">
            <lv-grid
              v-if="!hideArrowButton(text)"
              :key="text"
              type="item"
              xs="1/2"
              sm="min"
              :class="$style.tag"
            >
              <aw-arrow-button
                :label="text"
                allow-pevent
                arrow-direction="right"
                :outlined="!multipleSubstringFilter[i]"
                :aria-pressed="multipleSubstringFilter[i].toString()"
                @click="filterTag(i)"
              >
                <template #secondary>
                  <lv-button
                    styling="reset"
                    :aria-label="`remove ${text} from search`"
                    @click="searchRelatedText(searchText.filter(t => t !== text))"
                  >
                    <lv-icon name="times-16" :size="16" />
                  </lv-button>
                </template>
              </aw-arrow-button>
            </lv-grid>
          </template>
        </lv-grid>
        <p
          v-if="noResultsFor.length"
          :class="[$style.noResultsFor]"
          v-html="$awt('aw.search_page.multiple_substring_header.no_result_for', {no_result_for: noResultsFor.join(', ')})"
        />
      </template>
      <!-- endregion Multiple substrings -->

      <!-- region Single substring -->
      <template v-else-if="relatedSearches.keywords && relatedSearches.keywords.length">
        <aw-separator margin-top="search" margin-bottom="search" />
        <div :class="$style.keywordsWrapper">
          <p :class="$style.keywordsTitle">
            {{ $awt('aw.search_page.single_substring_header.related_searches') }}
          </p>
          <div v-if="isWholeLoadingCompleted" :class="$style.keywordsItemWrapper">
            <lv-button
              v-for="keyword in relatedSearches.keywords"
              :key="keyword.text"
              type="button"
              styling="link-primary"
              shrink
              :class="$style.keywordsItem"
              @click="searchRelatedText(keyword.text)"
            >
              {{ keyword.text }}
            </lv-button>
          </div>
        </div>
        <aw-separator margin-top="search" margin-bottom="search" />
      </template>
      <p v-if="!hasSuggestions && isWholeLoadingCompleted" :class="$style.headline">
        {{ $awt('aw.product_list.recommended_products_token') }}
      </p>
    </template>
    <!-- endregion Single substring -->
  </aw-product-list>
</template>

<script>
  import { mapState } from 'pinia';
  import { navigateTo, useRoute, useRuntimeConfig } from 'nuxt/app';
  import { defineAsyncComponent, nextTick } from 'vue';
  import { LvHeading, LvButton, LvIcon, LvGrid } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { Navigation, ID_SEARCH_LIST_TOP, focusRef } from '~~/common/config/Navigation.js';
  import urlMixin from '~~/common/mixins/urlMixin';
  import AwProductList from '~~/common/components/Page/ProductList/AwProductList';
  import AwArrowButton from '~~/common/components/Common/AwArrowButton';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useProductsStore } from '~~/common/stores/products';
  import AwSeparator from '~~/common/components/Common/AwSeparator';
  import AwProductListHeader from '~~/common/components/Page/ProductList/AwProductListHeader.vue';
  import { PUSH_VIEW_PRODUCT_LIST } from '~~/common/plugins/aw-analytics.js';

  export default {
    name: 'AwProductSearch',
    components: {
      AwProductListHeader,
      AwProductListOrderByAndMobileFilterModal: defineAsyncComponent(() => import('~~/common/components/Page/ProductList/AwProductListOrderByAndMobileFilterModal')),
      AwSeparator,
      AwArrowButton,
      LvHeading,
      LvButton,
      LvIcon,
      LvGrid,
      AwProductList,
    },
    mixins: [urlMixin],
    data () {
      const productsStore = useProductsStore();
      return {
        Navigation,
        ID_SEARCH_LIST_TOP,
        wholeLoadingCompleted: 0,
        moreLoadingCompleted: 0,
        multipleSubstringFilter: productsStore.searchText.map(() => false),
        relatedSearches: {},
        throttledSearchId: null,
        hasProductList: false,
        hasSuggestions: false,
      };
    },
    computed: {
      isWholeLoadingCompleted () {
        return this.wholeLoadingCompleted === 0 && this.hasProductList;
      },
      isMoreLoadingCompleted () {
        return this.moreLoadingCompleted === 0;
      },
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useProductsStore, {
        currentPage: state => state.currentPage,
        itemsPerPage: state => state.itemsPerPage,
        itemCount: state => state.itemCount,
        filterBy: state => state.filterBy,
        sortKey: state => state.sortKey,
        sortBy: state => state.sortBy,
        noResultsFor: state => state.noResultsFor,
        searchText: state => state.searchText,
        allowedSearchText (state) {
          let result;
          if (this.multipleSubstringFilter.includes(true)) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            result = state.searchText.filter((e, i) => self.multipleSubstringFilter[i]);
          } else {
            result = state.searchText;
          }
          return result.length ? result : [''];
        },
        refreshProducts: state => state.refreshProducts,
        products: state => state.products,
      }),
      isMultipleSubstring () {
        return this.searchText.length > 1;
      },
    },
    watch: {
      products: {
        deep: true,
        handler () {
          this.hasProductList = true;
        },
      },
      searchText (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.multipleSubstringFilter = this.searchText.map(() => false);
          navigateTo(this.getSearchUrl(newVal));
          this.getRelatedSearches();
        }
      },
      refreshProducts () {
        this.throttledSearch();
      },
    },
    async mounted () {
      const productsStore = useProductsStore();
      productsStore.setCategoryPageAttrs({
        currentPage: 1,
        sortKey: this.sortKey,
        sortBy: this.sortBy,
        filterBy: this.filterBy,
      });
      await this.throttledSearch();
      this.getRelatedSearches();
      this.multipleSubstringFilter = this.searchText.map(() => false);
      focusRef(this, { refId: Navigation[ID_SEARCH_LIST_TOP] });
    },
    beforeUnmount () {
      clearTimeout(this.throttledSearchId);
    },
    methods: {
      filterTag (i) {
        this.multipleSubstringFilter[i] = !this.multipleSubstringFilter[i];
        this.throttledSearch();
      },
      async loadMoreProductItems (loadingState) {
        const productsStore = useProductsStore();
        this.moreLoadingCompleted++;

        if (loadingState && this.itemCount === this.products.length) {
          loadingState.complete();
          return;
        }

        await productsStore.fetchProducts({
          isCached: true,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage + 1,
          filterParams: this.filterBy,
          sortKey: this.sortKey || null,
          sortBy: this.sortBy || null,
          multipleSubstring: this.allowedSearchText,
        },
        ).then((result) => {
          if (loadingState) {
            if (!result || this.itemCount === this.products.length) {
              loadingState.complete();
            } else {
              loadingState.loaded();
            }
          }
          this.moreLoadingCompleted--;
        });
      },
      async orderProductItems ({
        value: {
          sortBy,
          sortKey,
        },
      }) {
        const productsStore = useProductsStore();
        const data = {
          isCached: true,
          itemsPerPage: this.currentPage * this.itemsPerPage,
          page: 1,
          filterParams: this.filterBy,
          multipleSubstring: this.allowedSearchText,
          sortBy,
          sortKey,
        };

        this.moreLoadingCompleted++;
        await productsStore.fetchProducts(data).finally(() => {
          this.moreLoadingCompleted--;
        });
      },
      async throttledSearch () {
        clearTimeout(this.throttledSearchId);
        this.throttledSearchId = setTimeout(async () => {
          await this.filterProductItems();
        }, 800);
      },
      async filterProductItems () {
        const productsStore = useProductsStore();
        this.moreLoadingCompleted++;
        await productsStore.fetchProducts({
          isCached: true,
          page: 1,
          itemsPerPage: this.currentPage * this.itemsPerPage,
          filterParams: this.filterBy,
          sortKey: this.sortKey || null,
          sortBy: this.sortBy || null,
          multipleSubstring: this.allowedSearchText,
        },
        ).finally(() => {
          this.$awAnalytics[PUSH_VIEW_PRODUCT_LIST]({
            searchText: this.searchText,
            products: this.products,
          });
          nextTick(() => {
            nextTick(() => {
              this.moreLoadingCompleted--;
            });
          });
        });
      },
      async getRelatedSearches () {
        const config = useRuntimeConfig();
        const route = useRoute();
        this.wholeLoadingCompleted++;
        this.hasProductList = false;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        await Promise.all(function * () {
          if (!self.isMultipleSubstring) {
            yield self.$api.$get('/suggestions', {
              params: {
                page: 1,
                substring: self.searchText[0],
              },
            }).then((rs) => {
              self.relatedSearches = rs;
              self.hasSuggestions = Object.keys(rs.products).length > 0;
            });
          }
        }()).finally(() => {
          this.wholeLoadingCompleted--;
          if (config.public.isPrefixboxEnabled && config.public.prefixboxWebsiteTracker) {
            nextTick(() => {
              window.prefixboxAnalytics('result', 'serp', {
                pattern: route.query['q[]'],
                count: this.itemCount || 0,
                page: this.currentPage,
              });
            });
          }
        });
      },
      searchRelatedText (keywordText) {
        const productsStore = useProductsStore();
        productsStore.setSearchText(keywordText);
      },
      hideArrowButton (text) {
        return !this.multipleSubstringFilter.includes(true) && this.noResultsFor?.includes?.(text);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.title {
  margin-bottom: 24px;
}

.tag {
  margin-bottom: 3px;
}

.noResultsFor {
  margin-top: 25px;
}

.headline {
  margin-top: 12px;
  margin-bottom: 20px;
}

.keywordsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include tablet(min) {
    align-items: center;
    flex-direction: row;
    gap: 20px;

  }
}

.keywordsItem {
  font-size: 16px;
  font-weight: $font-weight-regular-v2;
  line-height: 16px;
  padding: 6px 8px;
  color: $color-text-primary;
  border-radius: 8px;
  background: $color-background-3;
  @include tablet(min) {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    padding: 6px 12px;

  }

  &:hover, &:active, &:focus {
    font-size: 14px;
    text-decoration: none !important;
    color: $color-text-primary;
    background: $color-background-3;
  }

  &Wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.keywordsTitle {
  font-size: 14px;
  font-weight: $font-weight-normal-v2;
  line-height: 20px;
  height: 100%;
  color: $color-text-secondary;

  @include tablet(min) {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
